import React from "react";
import { useOverrides } from "@quarkly/components";
import { Text, Box, Section } from "@quarkly/widgets";
const defaultProps = {
	"padding": "60px 0",
	"sm-padding": "40px 0",
	"color": "--dark"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"margin": "-16px -16px -16px -16px",
			"display": "flex",
			"flex-wrap": "wrap"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"padding": "16px 16px 16px 16px",
			"width": "33.333%",
			"md-width": "100%"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"flex-direction": "column"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"as": "h2",
			"font": "--headline1",
			"margin": "0 0 8px 0",
			"children": "FAQs"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"as": "p",
			"font": "--lead",
			"margin": "0",
			"color": "--dark",
			"children": "Відповіді на поширені запитання"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"padding": "16px 16px 16px 16px",
			"width": "66.66%",
			"md-width": "100%"
		}
	},
	"box4": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"flex-direction": "row",
			"flex-wrap": "wrap"
		}
	},
	"box5": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"flex-direction": "column",
			"width": "50%",
			"padding": "16px 16px 16px 16px",
			"sm-width": "100%"
		}
	},
	"box6": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"flex-direction": "column"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"as": "h3",
			"font": "--headline4",
			"margin": "12px 0",
			"children": "Які види автомобілів ви обслуговуєте?"
		}
	},
	"text3": {
		"kind": "Text",
		"props": {
			"as": "p",
			"font": "--base",
			"margin": "12px 0",
			"color": "--greyD2",
			"children": "Ми обслуговуємо всі типи легкових автомобілів, включаючи електромобілі. Для вантажівок чи спеціалізованої техніки звертайтеся для уточнення."
		}
	},
	"box7": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"flex-direction": "column",
			"width": "50%",
			"padding": "16px 16px 16px 16px",
			"sm-width": "100%"
		}
	},
	"box8": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"flex-direction": "column"
		}
	},
	"text4": {
		"kind": "Text",
		"props": {
			"as": "h3",
			"font": "--headline4",
			"margin": "12px 0",
			"children": "Які види автомобілів ви обслуговуєте?"
		}
	},
	"text5": {
		"kind": "Text",
		"props": {
			"as": "p",
			"font": "--base",
			"margin": "12px 0",
			"color": "--greyD2",
			"children": "Ми обслуговуємо всі типи легкових автомобілів, включаючи електромобілі. Для вантажівок чи спеціалізованої техніки звертайтеся для уточнення."
		}
	},
	"box9": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"flex-direction": "column",
			"width": "50%",
			"padding": "16px 16px 16px 16px",
			"sm-width": "100%"
		}
	},
	"box10": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"flex-direction": "column"
		}
	},
	"text6": {
		"kind": "Text",
		"props": {
			"as": "h3",
			"font": "--headline4",
			"margin": "12px 0",
			"children": "Чи використовуєте ви екологічні засоби для миття автомобілів?"
		}
	},
	"text7": {
		"kind": "Text",
		"props": {
			"as": "p",
			"font": "--base",
			"margin": "12px 0",
			"color": "--greyD2",
			"children": "Так, ми застосовуємо лише сертифіковані засоби, безпечні для довкілля та людей."
		}
	},
	"box11": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"flex-direction": "column",
			"width": "50%",
			"padding": "16px 16px 16px 16px",
			"sm-width": "100%"
		}
	},
	"box12": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"flex-direction": "column"
		}
	},
	"text8": {
		"kind": "Text",
		"props": {
			"as": "h3",
			"font": "--headline4",
			"margin": "12px 0",
			"children": "Чи можу я залишити автомобіль у вас на ніч?"
		}
	},
	"text9": {
		"kind": "Text",
		"props": {
			"as": "p",
			"font": "--base",
			"margin": "12px 0",
			"color": "--greyD2",
			"children": "Так, у нас є охоронювана стоянка для автомобілів."
		}
	},
	"box13": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"flex-direction": "column",
			"width": "50%",
			"padding": "16px 16px 16px 16px",
			"sm-width": "100%"
		}
	},
	"box14": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"flex-direction": "column"
		}
	},
	"text10": {
		"kind": "Text",
		"props": {
			"as": "h3",
			"font": "--headline4",
			"margin": "12px 0",
			"children": "Чи потрібен попередній запис?"
		}
	},
	"text11": {
		"kind": "Text",
		"props": {
			"as": "p",
			"font": "--base",
			"margin": "12px 0",
			"color": "--greyD2",
			"children": "Ми радимо записуватись заздалегідь, щоб уникнути черг. Проте ми також обслуговуємо клієнтів у порядку живої черги за наявності вільного часу."
		}
	},
	"box15": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"flex-direction": "column",
			"width": "50%",
			"padding": "16px 16px 16px 16px",
			"sm-width": "100%"
		}
	},
	"box16": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"flex-direction": "column"
		}
	},
	"text12": {
		"kind": "Text",
		"props": {
			"as": "h3",
			"font": "--headline4",
			"margin": "12px 0",
			"children": "Чи є у вас гарантія на виконані роботи?"
		}
	},
	"text13": {
		"kind": "Text",
		"props": {
			"as": "p",
			"font": "--base",
			"margin": "12px 0",
			"color": "--greyD2",
			"children": "Так, ми надаємо гарантію на всі послуги терміном до 6 місяців залежно від виду робіт."
		}
	}
};

const Faq1324 = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Box {...override("box")}>
			<Box {...override("box1")}>
				<Box {...override("box2")}>
					<Text {...override("text")} />
					<Text {...override("text1")} />
				</Box>
			</Box>
			<Box {...override("box3")}>
				<Box {...override("box4")}>
					<Box {...override("box5")}>
						<Box {...override("box6")}>
							<Text {...override("text2")} />
							<Text {...override("text3")} />
						</Box>
					</Box>
					<Box {...override("box7")}>
						<Box {...override("box8")}>
							<Text {...override("text4")} />
							<Text {...override("text5")} />
						</Box>
					</Box>
					<Box {...override("box9")}>
						<Box {...override("box10")}>
							<Text {...override("text6")} />
							<Text {...override("text7")} />
						</Box>
					</Box>
					<Box {...override("box11")}>
						<Box {...override("box12")}>
							<Text {...override("text8")} />
						</Box>
						<Text {...override("text9")} />
					</Box>
					<Box {...override("box13")}>
						<Box {...override("box14")}>
							<Text {...override("text10")} />
							<Text {...override("text11")} />
						</Box>
					</Box>
					<Box {...override("box15")}>
						<Box {...override("box16")}>
							<Text {...override("text12")} />
							<Text {...override("text13")} />
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
		{children}
	</Section>;
};

Object.assign(Faq1324, { ...Section,
	defaultProps,
	overrides
});
export default Faq1324;