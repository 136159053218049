import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Strong, Box, Section } from "@quarkly/widgets";
const defaultProps = {
	"padding": "80px 0 80px 0",
	"sm-padding": "60px 0 60px 0",
	"background": "--color-dark"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"width": "50%",
			"display": "flex",
			"flex-direction": "column",
			"justify-content": "space-between",
			"md-width": "100%",
			"padding": "0px 80px 0px 0px",
			"lg-padding": "0px 30px 0px 0px",
			"md-padding": "0px 0px 0px 0px"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"color": "--light",
			"font": "--headline2",
			"md-margin": "0px 0px 30px 0px",
			"children": <Strong
				overflow-wrap="normal"
				word-break="normal"
				white-space="normal"
				text-indent="0"
				text-overflow="clip"
				hyphens="manual"
				user-select="auto"
				pointer-events="auto"
			>
				Переваги для наших клієнтів
			</Strong>
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"width": "50%",
			"display": "flex",
			"flex-direction": "column",
			"justify-content": "space-between",
			"md-width": "100%"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 40px 0px",
			"color": "--light",
			"font": "--lead",
			"children": <>
				Гнучка система знижок для постійних клієнтів.
				<br />
				<br />
				Програма лояльності: кожне 5-те миття безкоштовне.
				<br />
				<br />
				Попередній запис онлайн або за телефоном для зручності.
			</>
		}
	}
};

const Bene = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
		<Box {...override("box")}>
			<Text {...override("text")} />
		</Box>
		<Box {...override("box1")}>
			<Text {...override("text1")} />
		</Box>
		{children}
	</Section>;
};

Object.assign(Bene, { ...Section,
	defaultProps,
	overrides
});
export default Bene;